<script>
  import {onMount} from 'svelte'
  import {LeaderLine} from './LeaderLine'
  import marked from 'marked'
  import {leaderlineEnds, leaderlineEls} from './model'
  import {tags, projMap, proj2tag, tag2proj, projList} from './myData'

  import Notice from './Notice.svelte'
  import ProjList from './ProjList.svelte'
  import Dot from './dot.svelte'
  import WordCloud from './WordCloud.svelte'
  import Projects from './Projects.svelte'

  import Knots from './projects/knots.svelte'
  import Jike from './projects/jike.svelte'

  const isSafari =
    navigator.userAgent.indexOf('Chrome') == -1 &&
    navigator.userAgent.indexOf('Safari') > -1

  let items = [1, 2, 3],
    showSingleLineTimers = Array(20).fill(0)
  let elProjList, elWordCloud, showLineTimer, viewingTag, viewingProj
  let showProject = false,
    currentProjectID = 'jike',
    projThemeColor = {
      adicted: '#ffbb00',
      knots: '#234',
    }

  onMount(() => {
    const id = window.location.hash.substr(1)
    if (!(id in projMap)) return
    setViewingProj(projMap[id])
    // viewingProj = projMap[id]
  })

  function setViewingProj(proj) {
    if (proj.isGH) {
      fetch(proj.url)
        .then((rsp) => rsp.text())
        .then((t) => {
          proj.HTML = marked(t)
          viewingProj = proj
          console.log('haha', t, viewingProj)
        })
    } else {
      viewingProj = proj
    }
    showProject = true
  }
</script>

<style lang="scss">:root {
  --light-blue: #69e; }

.proj-list {
  right: 0;
  width: 50%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 50vh;
  padding-top: 20vh; }
  .proj-list.bw {
    filter: saturate(10%); }

.me {
  position: fixed;
  top: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 46%;
  height: 100%;
  padding: 0 0 0 9%; }

.me__intro {
  font-size: 1.75rem;
  margin-top: 0; }

.safari-notice {
  position: fixed;
  top: 10px;
  left: 20px;
  color: #666;
  font-size: 0.8rem; }

.proj-bg-mask {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  background: #0007;
  height: 100vh;
  box-shadow: inset 2px -8px 8px #656565;
  transition: all 0.2s ease-out;
  transition-delay: 0.2s;
  opacity: 1;
  width: 20%; }
  .proj-bg-mask.collapsed {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0s;
    transition-duration: 0.1s; }
</style>

{#if isSafari}
  <div class="safari-notice">
    If the animation freezes, try to use Chrome rather than Safari.
  </div>
{/if}
<div class="me" class:collapsed={showProject}>
  <!-- <h1>Hi there, I'm YAO Ming.</h1>
  <h1>Skills & Interests</h1> -->
  <Notice>UNDER CONSTRUCTION</Notice>
  <p class="me__intro">
    Hi there, I’m YAO Ming.
    <br />You may get to know me at a glance, from here:
  </p>
  <WordCloud />
</div>
<ProjList
  {showProject}
  on:selectitem={(e) => {
    showProject = true
    console.log(showProject)
    currentProjectID = e.detail.id
    window.location.hash = '#' + currentProjectID
    setViewingProj(e.detail)
  }}
  on:hoveritem={(e) => {
    //proj
    showLineTimer = setTimeout(() => leaderlineEnds[e.detail.id] && leaderlineEnds[e.detail.id].forEach(
          (ends, i) => {
            leaderlineEls[i].end = document.body
            leaderlineEls[i].start = ends.from
            leaderlineEls[i].end = ends.to
            showSingleLineTimers[i] = setTimeout(() => {
              leaderlineEls[i].color = '#58ca58'
              leaderlineEls[i].startSocket = 'left'
              leaderlineEls[i].endSocket = 'bottom'
              leaderlineEls[i].middleLabel = ''
              leaderlineEls[i].position()
              leaderlineEls[i].show('draw')
            }, i * 100)
          },
        ), 300)
  }}
  on:leaveitem={(e) => {
    clearTimeout(showLineTimer)
    showSingleLineTimers.forEach((t) => clearTimeout(t))
    //proj
    leaderlineEls.forEach((l) => l.hide('draw'))
  }} />
<Projects {viewingProj} {showProject} />
<div
  class="proj-bg-mask"
  class:collapsed={!showProject}
  on:click={(e) => {
    showProject = false
    if (window.history.pushState) window.history.pushState(null, null, '/')
  }} />
<Dot />
