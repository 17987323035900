<script>
  import {lang} from '../lang'
  import Frame from '../ipxFrame.svelte'
  // your script goes here
</script>

<style lang="scss">.root {
  text-align: center;
  max-width: 70rem;
  margin: auto; }

p {
  margin: auto;
  max-width: 50rem;
  font-size: 1.1rem;
  line-height: 1.8; }

figure {
  display: inline-block; }
  figure img {
    width: 16rem; }

.jike-url {
  color: black;
  text-decoration: none;
  box-shadow: 0 3px #ffe411; }

.demo {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 7rem; }
  .demo b {
    font-size: 3rem; }
</style>

<div class="root">
  <h1>即刻Lite</h1>
  <p>
    根据自身需求开发的<a
      class="jike-url"
      href="https://www.okjike.com/"
      target="_blank">即刻App</a>（非官方）客户端，整体上复刻了原App的样式。<br />如果你不知道即刻是什么，可以去看看。它至少现在仍是中文互联网讨论氛围最好的开放社区。
  </p>
  <br />
  <p><b>主要改动：</b>提高信息展示效率，修改图片查看方式以及增加黑暗模式。</p>
  <br />
  <figure>
    <img src="./img/posts/jike.png" alt="" />
    <figcaption>
      <a
        class="jike-url"
        href="https://www.okjike.com/"
        target="_blank">即刻App</a>主页
    </figcaption>
  </figure>
  <figure>
    <img src="./img/posts/lite.png" alt="" />
    <figcaption>即刻Lite主页</figcaption>
  </figure>
  <figure>
    <img src="./img/posts/lite1.png" alt="" />
    <figcaption>即刻Lite主页</figcaption>
  </figure>
  <figure>
    <img src="./img/posts/dark.png" alt="" />
    <figcaption>即刻Lite黑暗模式</figcaption>
  </figure>
  <figure>
    <img src="./img/posts/reply0.png" alt="" />
    <figcaption>即刻Lite评论页</figcaption>
  </figure>
  <figure>
    <img src="./img/posts/reply.png" alt="" />
    <figcaption>即刻Lite评论页</figcaption>
  </figure>
  <div class="demo">
    <div>
      <b>在线Demo👉</b><br />
      <p>滑动鼠标开始浏览</p>
    </div>
    <Frame src="https://jike.gnimoay.com/#demo" />
  </div>
</div>
