<script>
  import {onMount} from 'svelte'
  import {fade, fly} from 'svelte/transition'
  import marked from 'marked'
  import {projMap, projList} from './myData'

  import Jike from './projects/jike.svelte'
  import Knots from './projects/knots.svelte'
  import Adicted from './projects/adicted.svelte'

  export let viewingProj
  export let showProject

  const keys = Object.keys(projMap)

  let nextProj,
    hasNext = false,
    elProj,
    loading = false

  $: {
    let i = viewingProj && keys.indexOf(viewingProj.id) + 1
    if (keys[i] === 'year-tag') i++
    hasNext = i < keys.length
    nextProj = hasNext ? projMap[keys[i]] : null
    console.log('lalal', i, keys[i])
  }
</script>

<style lang="scss">.project {
  margin: 0;
  padding: 0;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  background: #fafafc;
  height: 100%;
  width: calc(100vw - 10rem);
  overflow: hidden scroll; }
  .project::-webkit-scrollbar {
    display: none; }

.project__md {
  margin: auto;
  max-width: 800px;
  padding: 2rem; }

.project__link {
  background: #fafafa;
  box-shadow: 1px 2px 7px #0004;
  border-radius: 10px;
  padding: 0.2rem 0.9rem;
  position: absolute;
  right: 2rem;
  top: 1rem; }

.mask {
  z-index: 20000;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #333; }

.side {
  position: fixed;
  top: 0;
  left: -2px;
  height: 100%;
  width: 2.5rem;
  box-shadow: 2px 4px 11px #888;
  border-radius: 0 20px 20px 0;
  background-color: #fafafa;
  will-change: color;
  transition: background-color ease 0.5s;
  transform: scale(0.9); }
  .side.collapsed {
    background-color: black; }

.container {
  height: 100vh;
  width: 100%; }

.next-proj {
  overflow: hidden;
  display: grid;
  grid-template: 2fr 3fr/1fr 1fr;
  color: wheat;
  position: relative;
  margin-top: 6%; }
  .next-proj .bg {
    grid-column: 1/3;
    grid-row: 1/3;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 120%;
    top: 100px;
    left: 0;
    transform: skewY(6deg);
    background: #515152; }
  .next-proj h1 {
    text-align: center;
    grid-row: 1/2;
    grid-column: 1/2;
    width: fit-content;
    margin: 6rem 6rem 0 6rem;
    cursor: pointer;
    align-self: end;
    justify-self: center; }
    .next-proj h1 span {
      pointer-events: none; }
  .next-proj img {
    grid-column: 2/3;
    grid-row: 1/3;
    cursor: pointer;
    border-radius: 12px;
    height: 350px;
    max-width: 80%;
    object-fit: cover;
    margin: 2rem auto 4rem 2rem;
    box-shadow: 2px 8px 13px #333;
    justify-self: start; }
  .next-proj .intro {
    font-size: 1.4rem;
    margin: 0 6rem 4rem 6rem;
    grid-row: 2/3;
    grid-column: 1/2;
    justify-self: center;
    align-self: center;
    text-align: center;
    max-width: 90%;
    white-space: white-space; }
</style>

<div class="side" class:collapsed={!showProject} />
{#if showProject}
  <div
    class="project"
    bind:this={elProj}
    transition:fly={{x: -200, duration: 500}}>
    {#if loading}
      <div class="mask" transition:fade={{duration: 200}} />
    {/if}
    {#if viewingProj && viewingProj.id == 'jike'}
      <Jike />
    {:else if viewingProj && viewingProj.id == 'knots'}
      <Knots />
    {:else if viewingProj && viewingProj.id == 'adicted'}
      <Adicted />
    {:else if viewingProj && viewingProj.url}
      {#if viewingProj.isGH}
        <a class="project__link" href={viewingProj.rawUrl} target="_blank"><div>
            View in Github
          </div></a>
        <div class="project__md">
          {@html viewingProj.HTML && viewingProj.HTML.replaceAll('https://github.com/', 'https://raw.githubusercontent.com/').replaceAll('/blob/', '/')}
        </div>
      {:else}
        <a class="project__link" href={viewingProj.url} target="_blank"><div>
            View original
          </div></a>

        <iframe
          style="border:none;"
          width="100%"
          height="100%"
          src={viewingProj && viewingProj.url} />
      {/if}
    {/if}

    {#if hasNext}
      <div
        class="next-proj"
        on:click={(e) => {
          if (e.target.nodeName == 'H1' || e.target.nodeName == 'IMG') {
            viewingProj = nextProj
            if (viewingProj.isGH) {
              fetch(viewingProj.url)
                .then((rsp) => {
                  const t = rsp.text()
                  console.log(t)
                  return t
                })
                .then((t) => (viewingProj.HTML = marked(t)))
            }

            let i = keys.indexOf(viewingProj.id) + 1
            if (keys[i] === 'year-tag') i++
            hasNext = i < keys.length
            nextProj = hasNext ? projMap[keys[i]] : null

            window.location.hash = '#' + viewingProj.id
            elProj.scrollTo({top: 0, behavior: 'smooth'})
            loading = true
            setTimeout(() => {
              loading = false
            }, 400)
          }
        }}>
        <!-- <div class="bg-container"> -->
        <div class="bg" />
        <!-- </div> -->
        <h1>Next Project:<br /> <span>{nextProj.title}</span></h1>
        <img
          src={nextProj.coverUrl + (nextProj.useRawImg ? '' : '-small.jpg')}
          alt="" />
        <div class="intro">{nextProj.brief ? nextProj.brief : ''}</div>
      </div>
    {/if}
  </div>
{/if}
