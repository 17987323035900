<script>
  // export let msg = ''
</script>

<style>
  i {
    margin: 0 auto 2rem;
    background: #34a;
    color: white;
  }
</style>

<i><slot /></i>
