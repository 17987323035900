<script>
  export let src = ''
  export let className = ''
  export let size = 'm'
</script>

<style lang="scss">.root {
  display: inline-block;
  margin: 1rem;
  position: relative; }
  .root.m {
    width: 37.5vh;
    height: 81vh; }

.frame {
  position: absolute;
  pointer-events: none; }
  .frame.m {
    left: -2.8vh;
    top: -2.5vh;
    width: calc(100% + 5.5vh); }

iframe {
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 20px; }
</style>

<div class={size + ' root ' + className}>
  <img class={'frame ' + size} src="./img/ipxFrame.png" />
  <iframe {src} />
</div>
