<script>
  import Notice from '../Notice.svelte'
</script>

<style>
  .root {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
  }
</style>

<div class="root">
  <Notice>UNDER CONSTRUCTION</Notice>

  <h1>adicted - 换个思路记单词</h1>
  <img src="./img/list/adicted-preview.png" alt="" />
  <h3>Edge商店下载地址：</h3>
  <a
    href="https://microsoftedge.microsoft.com/addons/detail/adicted/dghgahgdekffflappaiofmlilppifgme">点击查看</a>
  <h3>Chrome商店下载地址：</h3>
  <a
    href="https://chrome.google.com/webstore/detail/adicted/gmjgpfoefogpodaaiinlajlaapbmjkig">点击查看</a>
</div>
